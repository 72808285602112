
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HasAnyRoleGuard, IsAuthenticatedGuard } from '@lexi/oidc-uaa';
import { SettingsComponent } from './components/settings/settings.component';
import { CreationSocieteComponent } from './components/creation-societe/creation-societe.component';
import { EvenementTypeListComponent } from './components/evenement-type-list/evenement-type-list.component';
import { IsRootGuard } from './guards/is-root.guard';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ArticleListComponent } from './components/article-list/article-list.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { PartenaireListComponent } from './components/partenaire-list/partenaire-list.component';
import { PartenaireDetailComponent } from './components/partenaire-detail/partenaire-detail.component';
import { IsCollaborateurGuard } from './guards/is-collaborateur.guard';
import { CanAccessModuleKpiGuard } from './guards/can-access-module-kpi.guard';
import { CanAccessModuleLogistiqueGuard } from './guards/can-access-module-logistique.guard';
import { CanAccessModuleVenteGuard } from './guards/can-access-module-vente.guard';
import { CanAccessModuleFretLocalGuard } from './guards/can-access-module-fret-local.guard';
import { CanAccessModuleAdministrationGuard } from './guards/can-access-module-administration.guard';
import { IsPartenaireGuard } from './guards/is-partenaire.guard';
import { TransporteurListComponent } from './components/transporteur-list/transporteur-list.component';
import { Permissions } from '@lexi-clients/lexi';
import { StockViewComponent } from './modules/shared/stock-view/stock-view.component';
import { BonListComponent } from './modules/shared/bon/bon-list/bon-list.component';
import { MouvementStockViewComponent } from './modules/shared/mouvement-stock-view/mouvement-stock-view.component';
import { BonDetailComponent } from './modules/shared/bon/bon-detail/bon-detail.component';
import { SiteDetailComponent } from './modules/admin/site-list/site-detail/site-detail.component';
import { ManageStockGuard } from './guards/manage-stock.guard';
import { DocumentListComponent } from './components/document-list/document-list.component';

const routes: Routes = [
  // Routes accessibles à un Partenaire et à un Collaborateur
  {
    path: 'roadmap',
    loadChildren: () => import('./modules/roadmap/roadmap.module').then(m => m.RoadmapModule),
    canActivate: [IsAuthenticatedGuard]
  },

  // Routes accessibles uniquement aux utilisateurs de type Collaborateur
  { path: 'evenement-types', component: EvenementTypeListComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard], },
  { path: 'creation-societe', component: CreationSocieteComponent , canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard] },
  { path: 'profil', component: UserProfileComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard] },
  { path: 'articles', component: ArticleListComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },
  { path: 'article/:id', component: ArticleDetailComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },
  { path: 'clients', component: PartenaireListComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },
  { path: 'fournisseurs', component: PartenaireListComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },
  { path: 'partenaires', component: PartenaireListComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },
  { path: 'client/:id', component: PartenaireDetailComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },
  { path: 'fournisseur/:id', component: PartenaireDetailComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },
  { path: 'partenaire/:id', component: PartenaireDetailComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },

  { path: 'transporteurs', component: TransporteurListComponent, canActivate: [IsAuthenticatedGuard, HasAnyRoleGuard], data: { roles: [Permissions.canGererTransporteurs] }  },
  {
    path: 'administration',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleAdministrationGuard]
  },
  { path: 'visualisation-stock', component: StockViewComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, HasAnyRoleGuard, ManageStockGuard], data: { roles: [Permissions.accesVueConsultationStock] } },
  { path: 'visualisation-des-mouvements', component: MouvementStockViewComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, HasAnyRoleGuard], data: { roles: [Permissions.accesVueConsultationStock] } },
  { path: 'visualisation-des-bons', component: BonListComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, ManageStockGuard] },
  { path: 'bon/:id', component: BonDetailComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, ManageStockGuard] }, 
  { path: 'site-en-cours', component: SiteDetailComponent, canActivate: [IsAuthenticatedGuard, HasAnyRoleGuard], data: { roles: [Permissions.canManageSite] }},
  { path: 'document', component: DocumentListComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]  },

  // Modules présents dans NavigationTop (Collaborateur only)
  {
    path: 'vente',
    loadChildren: () => import('./modules/vente/vente.module').then(m => m.VenteModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleVenteGuard]
  },
  {
    path: 'logistique',
    loadChildren: () => import('./modules/logistique/logistique.module').then(m => m.LogistiqueModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleLogistiqueGuard]
  },
  {
    path: 'sav',
    loadChildren: () => import('./modules/sav/sav.module').then(m => m.SavModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]
  },
  {
    path: 'douane',
    loadChildren: () => import('./modules/douane/douane.module').then(m => m.DouaneModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]
  },
  {
    path: 'fret-local',
    loadChildren: () => import('./modules/fret-local/fret-local.module').then(m => m.FretLocalModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleFretLocalGuard]
  },
  {
    path: 'kpi',
    loadChildren: () => import('./modules/kpi/kpi.module').then(m => m.KpiModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleKpiGuard]
  },
  {
    path: 'editions',
    loadChildren: () => import('./modules/edition/edition.module').then(m => m.EditionModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard, IsRootGuard]
  },
  {
    path: 'securite',
    loadChildren: () => import('./modules/security/access-management.module').then(m => m.AccessManagementModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]
  },
  {
    path: 'comptabilite',
    loadChildren: () => import('./modules/comptabilite/comptabilite.module').then(m => m.ComptabiliteModule),
    canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard]
  },

  // Portail client (Partenaire only)
  {
    path: 'portail-client',
    loadChildren: () => import('./modules/portail-client/portail-client.module').then(m => m.PortailClientModule),
    canActivate: [IsAuthenticatedGuard, IsPartenaireGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
