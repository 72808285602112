<div class="form-container">
  <!-- Entête -->
  <dx-box direction="row" width="100%" [height]="50" align="center" crossAlign="center">
    <dxi-item class="back-button" [ratio]="1">
      <a routerLink="/articles">
        <dx-button icon="back"></dx-button>
      </a>
    </dxi-item>

    <dxi-item [ratio]="4">
      <h2 class="content-block">
        <span *ngIf="!isCreation" class="libelle-article">{{ article?.codeBo }} - {{ article?.libelleLong }}</span>
        <span *ngIf="isCreation">Création d'un nouvel article</span>
      </h2>
    </dxi-item>

    <dxi-item [ratio]="1"></dxi-item>
  </dx-box>

  <!-- Boutons d'action -->
  <dx-box direction="row" width="100%">
    <dxi-item [ratio]="1" [visible]="isCreation || isModificationEnCours">
      <div>
        <dx-button class="action-button" text="Enregistrer" type="success" icon="save" (onClick)="onUpdateArticle()" [visible]="!isCreation && isModificationEnCours"></dx-button>
        <dx-button class="action-button" text="Annuler" type="danger" icon="undo" (onClick)="onCancel()"  [visible]="!isCreation && isModificationEnCours"></dx-button>
        <dx-button  class="action-button" text="Créer" type="success" icon="save" (onClick)="onCreateNewArticle()" [visible]="isCreation"></dx-button>
      </div>
    </dxi-item>
    <dxi-item [ratio]="1" *ngIf="!isCreation">
      <div class="menu-button">
        <app-objecttype-edition-possible-list [objectType]="objectType.article" [objectId]="article?.id" [isDropDownButton]="true"></app-objecttype-edition-possible-list>
        <dx-drop-down-button
          [items]="actions"
          keyExpr="id"
          displayExpr="intitule"
          text="Action"
          icon="smalliconslayout"
          [dropDownOptions]="{ width: 120 }"
          [disabled]="isModificationEnCours || !isModificationAutorisee"
          (onItemClick)="onActionClick($event)"
          style="margin-left: 10px;"
        ></dx-drop-down-button>
      </div>
    </dxi-item>
  </dx-box>

  <div *ngIf="article" style="padding-top: 15px">
    <dx-form #form id="form" [formData]="article" labelLocation="top" [readOnly]="!isModificationEnCours && !isCreation">
      <dxi-item itemType="tabbed">
        <dxo-tab-panel-options [showNavButtons]="true" [onSelectionChanged]="onTabSelectionChanged"></dxo-tab-panel-options>
        <!-- Onglet Général -->
        <dxi-tab title="Général" [colCount]="4">
          <!-- Informations générales -->
          <dxi-item itemType="group" caption="Informations générales">
            <dxi-item dataField="codeBo" editorType="dxTextBox" [label]="{text: 'Code'}" [editorOptions]="{ maxLength: 36 }"></dxi-item>
            <dxi-item dataField="libelleLong" editorType="dxTextBox" [label]="{text: 'Intitulé'}" [editorOptions]="{ maxLength: 100 }">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="libelleCourt" editorType="dxTextBox" [label]="{text: 'Intitulé Court'}" [editorOptions]="{ maxLength: 20 }"></dxi-item>
            <dxi-item dataField="libelle2" editorType="dxTextBox" [label]="{text: 'Intitulé 2'}" [editorOptions]="{ maxLength: 100 }"></dxi-item>
            <dxi-item dataField="libelle3" editorType="dxTextBox" [label]="{text: 'Intitulé 3'}" [editorOptions]="{ maxLength: 100 }"></dxi-item>
            <dxi-item itemType="group" [colCount]="2">
              <dxi-item dataField="isPpn" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Est PPN'}"></dxi-item>
              <dxi-item dataField="isPgc" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Est PGC'}"></dxi-item>
              <dxi-item dataField="bloque" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Bloqué'}"></dxi-item>
              <dxi-item dataField="suiviSav" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Suivi SAV'}"></dxi-item>
              <dxi-item dataField="matiereDangereuse" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Matière dangereuse'}"></dxi-item>
              <dxi-item dataField="marchandiseFragile" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Fragile'}"></dxi-item>
              <dxi-item dataField="_synchronized" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Synchronisé'}"></dxi-item>
              <dxi-item *ngIf="canGererBonAchat" dataField="isBonAchat" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Bon d\'achat'}"></dxi-item>
              <dxi-item dataField="genereChiffreAffaire" editorType="dxCheckBox" [label]="{ text: ' ' }" [editorOptions]="{text: 'Génère CA'}"></dxi-item>
            </dxi-item>
            <dxi-item dataField="notes" [label]="{text: 'Notes'}" editorType="dxTextArea" [editorOptions]="{height: 80}"></dxi-item>
            <!-- <dxi-item dataField="url" editorType="dxTextBox" [label]="{ text: 'URL' }" [editorOptions]="{ maxLength: 255 }"></dxi-item> -->
            <!-- <dxi-item dataField="presentationWeb" editorType="dxTextBox" [label]="{ text: 'Présentation WEB' }"></dxi-item> -->
            <!-- <dxi-item dataField="resume" editorType="dxTextBox" [label]="{ text: 'Résumé' }"></dxi-item> -->
            <!-- <dxi-item dataField="uniteComplementaire" [label]="{text: 'Unité complémentaire'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 3 } }"></dxi-item> -->
          </dxi-item>

          <!-- Vente -->
          <dxi-item itemType="group" caption="Vente">
            <dxi-item
              dataField="type"
              [label]="{text: 'Type de l\'article'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: articleTypeDataSource,
                searchEnabled: true,
                displayExpr: 'libelle',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['libelle'],
                showDataBeforeSearch: true
              }"
            >
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="rapportTarifaire" [label]="{text: 'Rapport tarifaire'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 4 } }"></dxi-item>
            <dxi-item dataField="rapportPhysique" [label]="{text: 'Rapport physique'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 4 } }"></dxi-item>
            <dxi-item dataField="dureeGarantieMois" [label]="{text: 'Durée de la garantie (en nombre de mois)'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item>
            <dxi-item
              dataField="conditionnementTarifaireVenteId"
              [label]="{text: 'Conditionnement tarifaire'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: conditionnementDataSource,
                searchEnabled: true,
                displayExpr: 'libelle',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['libelle'],
                showDataBeforeSearch: true
              }"
            ></dxi-item>

            <dxi-item *ngIf="!isCreation && (!isModificationEnCours || (isModificationEnCours && !canModifierPrixRevient))"
              dataField="prixRevient"
              [label]="{text: 'Prix de revient'}"
              editorType="dxNumberBox"
              [editorOptions]="{format: {type: 'fixedPoint', precision: 3}, disabled:'true'}"
            ></dxi-item>

            <dxi-item dataField="prixRevient"
              *ngIf="!isCreation && isModificationEnCours && canModifierPrixRevient"
              editorType="dxNumberBox"
              [editorOptions]="{format: {type: 'fixedPoint', precision: 3}, disabled:'true'}"
            >
              <dxo-label template="prixRevientLabelTemplate"></dxo-label>
            </dxi-item>
          </dxi-item>

          <!-- Comptabilité -->
          <dxi-item itemType="group" caption="Comptabilité">
            <dxi-item
              dataField="taxeGroupeId"
              [label]="{text: 'Groupe de taxes'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: taxeGroupeDataSource,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule'],
                showDataBeforeSearch: true
              }"
            >
            </dxi-item>

            <dxi-item
              dataField="compteCharge"
              [label]="{ text: 'Compte de charge' }"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: comptesGeneraux,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'code',
                showClearButton: true,
                searchExpr: ['code', 'intitule'],
                showDataBeforeSearch: true,
                placeholder: (infosHeriteesDeLaClassification?.compteCharge ?? (isInterfaceComptableActive ? 'Nécessaire pour l\'interface comptable' : 'Sélection...'))
              }"
            ></dxi-item>

            <dxi-item
              dataField="compteProduit"
              [label]="{ text: 'Compte de produit' }"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: comptesGeneraux,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'code',
                showClearButton: true,
                searchExpr: ['code', 'intitule'],
                showDataBeforeSearch: true,
                placeholder: (infosHeriteesDeLaClassification?.compteProduit ?? (isInterfaceComptableActive ? 'Nécessaire pour l\'interface comptable' : 'Sélection...'))
              }"
            ></dxi-item>

            <dxi-item
              dataField="compteVariationStock"
              [label]="{ text: 'Compte variation de stock' }"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: comptesGeneraux,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'code',
                showClearButton: true,
                searchExpr: ['code', 'intitule'],
                showDataBeforeSearch: true,
                placeholder: (infosHeriteesDeLaClassification?.compteVariationStock ?? (isInterfaceComptableActive ? 'Nécessaire pour l\'interface comptable' : 'Sélection...'))
              }"
            ></dxi-item>

            <dxi-item
              dataField="compteStock"
              [label]="{ text: 'Compte de stock' }"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: comptesGeneraux,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'code',
                showClearButton: true,
                searchExpr: ['code', 'intitule'],
                showDataBeforeSearch: true,
                placeholder: (infosHeriteesDeLaClassification?.compteStock ?? (isInterfaceComptableActive ? 'Nécessaire pour l\'interface comptable' : 'Sélection...'))
              }"
            ></dxi-item>

            <dxi-item
              dataField="compteRemise"
              [label]="{ text: 'Compte Remise' }"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: comptesGeneraux,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'code',
                showClearButton: true,
                searchExpr: ['code', 'intitule'],
                showDataBeforeSearch: true,
                placeholder: 'Sélection...'
              }"
            ></dxi-item>
          </dxi-item>

          <!-- Logistique -->
          <dxi-item itemType="group" caption="Logistique">
            <dxi-item dataField="quantiteMinimumVente" editorType="dxNumberBox" [label]="{ text: 'Quantité minimum de vente' }" [editorOptions]="{ format: { type: 'fixedPoint', precision: 3 } }"></dxi-item>
            <dxi-item
              dataField="uniteBaseId"
              [label]="{text: 'Unité de base'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: uniteDataSource,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule'],
                showDataBeforeSearch: true,
                value: uniteBaseSelectedId
              }"
            ></dxi-item>
            <dxi-item
              *ngIf="!isCreation"
              dataField="conditionnementVenteId"
              [label]="{text: 'Conditionnement de vente'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: conditionnementDataSource,
                searchEnabled: true,
                displayExpr: 'libelle',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['libelle'],
                showDataBeforeSearch: true
              }"
            ></dxi-item>
            <dxi-item
              *ngIf="!isCreation"
              dataField="conditionnementAchatId"
              [label]="{text: 'Conditionnement d\'achat'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: conditionnementDataSource,
                searchEnabled: true,
                displayExpr: 'libelle',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['libelle'],
                showDataBeforeSearch: true
              }"
            ></dxi-item>
            <dxi-item dataField="nombreNumeroSerie" [label]="{text: 'Nombre n° série'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item>
            <dxi-item
              dataField="codeTarifRevatua"
              [label]="{text: 'Code tarif REVATUA'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: tarifRevatuaDataSource,
                searchEnabled: true,
                displayExpr: codeTarifRevatuaDisplayExpr,
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule', 'code'],
                showDataBeforeSearch: true,
                placeholder: codeTarifRevatuaPlaceholder
              }"
            ></dxi-item>
            <dxi-item
              dataField="stockageRevatua"
              [label]="{text: 'Stockage REVATUA'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: stockageRevatuaList,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule'],
                showDataBeforeSearch: true,
                placeholder: stockageRevatuaPlaceholder
              }"
            ></dxi-item>
          </dxi-item>

          <dxi-item itemType="group" caption="Tarifs" [colSpan]="4">
            <app-tarif-article-list [articleId]="article?.id"></app-tarif-article-list>
          </dxi-item>
        </dxi-tab>

        <!-- Onglet Stock -->
        <dxi-tab title="Stock" [disabled]="isCreation">
          <app-lot-stockage-datagrid
            *ngIf="!isCreation"
            [articleId]="article?.id"
            [currentSiteId]="currentSiteId"
            [dataGridMarginTop]="'calc(100vh - 300px)'"
            [isInFicheArticle]="true"
            [configFiltre]="configFiltreLot"
          ></app-lot-stockage-datagrid>
        </dxi-tab>

        <!-- Onglet Mouvement stock -->
        <dxi-tab title="Mouvement stock" [disabled]="isCreation">
          <app-mouvement-stock-datagrid
            *ngIf="!isCreation"
            [configFiltre]="configFiltre"
            [articleId]="article?.id"
            [currentPartenaireId]="currentPartenaireId"
            [dataGridHeight]="700"
          ></app-mouvement-stock-datagrid>
        </dxi-tab>

        <!-- Onglet Classifications -->
        <dxi-tab title="Classifications" [colCount]="2">
          <dxi-item
            dataField="familleId"
            [label]="{text: 'Famille d\'articles'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: familleDataSource,
              searchEnabled: true,
              displayExpr: 'libelleLong',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['libelleLong'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item itemType="empty"></dxi-item>
          <dxi-item
            dataField="articleParentId"
            [label]="{text: 'Article parent'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: articleDataSource,
              searchEnabled: true,
              displayExpr: 'libelleLong',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['libelleLong', 'codeBo'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>
          <dxi-item itemType="empty"></dxi-item>

          <!-- DataGrid Classifications -->
          <dxi-item itemType="group" caption="Classifications" *ngIf="!isCreation">
            <app-classification-datagrid [objectType]="objectType.article" [itemId]="article?.id"></app-classification-datagrid>
          </dxi-item>
        </dxi-tab>

        <!-- Onglet Achats -->
        <dxi-tab title="Achats">
          <dxi-item
            dataField="fournisseurPrincipalId"
            [label]="{text: 'Fournisseur principal'}"
            editorType="dxSelectBox"
            [editorOptions]="{
              dataSource: partenaireDataSource,
              searchEnabled: true,
              displayExpr: 'intitule',
              valueExpr: 'id',
              showClearButton: true,
              searchExpr: ['intitule'],
              showDataBeforeSearch: true
            }"
          ></dxi-item>

          <dxi-item itemType="group" caption="Références fournisseurs" *ngIf="!isCreation">
            <div style="margin-bottom: 6px;">
              <dx-button text="Rafraîchir" (onClick)="setReferencesFournisseurs()"></dx-button>
            </div>
            <dx-data-grid
              id="gridContainer"
              [dataSource]="referencesFournisseurs"
              [allowColumnReordering]="true"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [showBorders]="true"
            >
              <!-- Toolbar -->
              <dxo-toolbar>
                <dxi-item>
                  <div *dxTemplate>
                    <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setReferencesFournisseurs()"></dx-button>
                  </div>
                </dxi-item>
              </dxo-toolbar>
              <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
              <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>

              <dxi-column dataField="fournisseurIntitule" caption="Libellé"></dxi-column>
              <dxi-column dataField="codeArticleFournisseur" caption="Référence"></dxi-column>
            </dx-data-grid>
          </dxi-item>
        </dxi-tab>

        <!-- Onglet Vente -->
        <dxi-tab title="Vente" [disabled]="isCreation">
          <dxi-item itemType="group" caption="Opérations commerciales">
            <app-operations-commerciales-pour-article-detail *ngIf="!isCreation" [articleId]="article?.id"></app-operations-commerciales-pour-article-detail>
          </dxi-item>
        </dxi-tab>

        <!-- Onglet Logistique -->
        <dxi-tab title="Logistique" [disabled]="isCreation" [colCount]="2">
          <dxi-item itemType="group" caption="Conditionnements" [colSpan]="2">
            <app-conditionnement-list *ngIf="!isCreation" [articleId]="article?.id" [uniteBaseId]="article.uniteBaseId"></app-conditionnement-list>
          </dxi-item>
          <dxi-item itemType="group" caption="Codes barres" *ngIf="!isCreation" [colSpan]="2">
            <app-code-barre-article-detail [articleId]="article.id" [uniteBaseId]="article.uniteBaseId"></app-code-barre-article-detail>
          </dxi-item>
        </dxi-tab>

        <!-- Onglet Douane -->
        <dxi-tab title="Douane" [colCount]="4">
          <dxi-item itemType="group" [colSpan]="1">
            <dxi-item
              dataField="nomenclatureDouaneId"
              [label]="{text: 'Nomenclature douanière'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: nomenclatureDouaneDataSource,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule', 'codeSH'],
                showDataBeforeSearch: true
              }"
            ></dxi-item>
            <dxi-item
              dataField="suffixeValeurDouaneId"
              [label]="{text: 'Suffixe valeur douane'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: suffixeValeurDataSource,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule', 'code'],
                showDataBeforeSearch: true
              }"
            ></dxi-item>
            <dxi-item
              dataField="codeExonerationDouaneId"
              [label]="{text: 'Code d\'exonération douanière'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: codeExonerationDataSource,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule', 'code'],
                showDataBeforeSearch: true
              }"
            ></dxi-item>
            <dxi-item
              dataField="paysOrigineId"
              [label]="{text: 'Pays d\'origine'}"
              editorType="dxSelectBox"
              [editorOptions]="{
                dataSource: paysDataSource,
                searchEnabled: true,
                displayExpr: 'intitule',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['intitule'],
                showDataBeforeSearch: true
              }"
            ></dxi-item>
            <dxi-item dataField="tauxSucre" [label]="{text: 'Taux de sucre'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 3 } }"></dxi-item>
            <dxi-item dataField="litrage" [label]="{text: 'Litrage'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 3 } }"></dxi-item>
          </dxi-item>
        </dxi-tab>

        <!-- Onglet Audit -->
        <dxi-tab title="Audit">
          <app-audit-piste objectType="article" [objectId]="article.id"></app-audit-piste>
        </dxi-tab>

        <!-- Documents -->
        <dxi-tab title="Documents">
          <app-document-list [objectId]="article.id" [objectType]="objectType.article"></app-document-list>
        </dxi-tab>

        <!-- Onglet Notes -->
        <dxi-tab title="Notes">
          <app-notes-list [objectType]="objectType.article" [objectId]="article.id"></app-notes-list>
        </dxi-tab>

        <!-- Onglet Numéro de série -->
        <dxi-tab title="Numéro de série">
          <app-registre-numero-serie-list [articleId]="article.id" [canSelectionnerArticle]="false" [height]="'68vh'"></app-registre-numero-serie-list>
        </dxi-tab>

        <!-- Onglet Bons -->
        <dxi-tab title="Bons">
            <app-article-detail-bon-list [articleId]="article.id" [currentPartenaireId]="currentPartenaireId" [height]="'75vh'"></app-article-detail-bon-list>
        </dxi-tab>
        <!-- Onglet Caractéristique -->
        <dxi-tab title="Caractéristique">
          <app-gestion-caracteristiques [objectId]="article.id" [objectType]="objectType.article"></app-gestion-caracteristiques>
        </dxi-tab>

        <!-- Onglet Bons d'achat -->
        <dxi-tab title="Bons d'achat" *ngIf="article.isBonAchat">
          <app-bon-achat-datagrid [articleId]="article.id"></app-bon-achat-datagrid>
        </dxi-tab>
      </dxi-item>

      <div *dxTemplate="let data of 'prixRevientLabelTemplate'">
        <div id="template-content">
          <span>Prix de revient</span>
          <a (click)='opentPrixRevientPopup()'
            href="javascript:void(0);"
            style="text-decoration: none; margin-left: 5px;"
          >
            <i class="dx-icon-edit"></i>
          </a>
        </div>
      </div>

    </dx-form>
  </div>
</div>

<!-- Popup : modification du prix de revient -->
<dx-popup
  [width]="320"
  [height]="150"
  title = "Modifier le prix de revient"
  [(visible)]="showEditPrixRevientPopup"
  [showCloseButton]="true"
>
  <div *dxTemplate="let data of 'content'">
    <div style="text-align: center;">
      <dx-number-box
      [format]="{type: 'fixedPoint', precision: 3}"
      [(value)]="article.prixRevient"
      [width]="150"
      ></dx-number-box>
    </div>
    <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px">
      <dx-button text="Enregistrer" type="success" icon="save" (onClick)="onUpdatePrixRevient()" [visible]="!isCreation && isModificationEnCours" style="margin-right: 10px;"></dx-button>
      <dx-button class="action-button" text="Annuler" type="danger" icon="undo"
        (onClick)="this.showEditPrixRevientPopup=false; this.article.prixRevient = this.prixRevientAvantModification;"
        [visible]="!isCreation && isModificationEnCours">
      </dx-button>
    </div>
  </div>
</dx-popup>

<!-- Popup : Confirmation création d'article groupe taxe -->
<dx-popup [width]="450" [height]="'auto'" [(visible)]="showConfirmationTaxePopup" [showTitle]="true" [dragEnabled]="false"
          [showCloseButton]="true" title="Confirmation d'ajout">
  <div *dxTemplate="let data of 'content'">
    Cet article n'a pas de groupe de taxe.<br/>
    Voulez-vous vraiment le créer ?
    <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px">
      <dx-button text="Enregistrer" type="success" icon="save" (onClick)="createArticle()" style="margin-right: 10px;"></dx-button>
      <dx-button class="action-button" text="Annuler" type="danger" icon="undo"
                 (onClick)="this.showConfirmationTaxePopup=false">
      </dx-button>
    </div>
  </div>
</dx-popup>
